body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ol,
ul {
  list-style: none;
}

/* Forecast Grid */
.forecastHours {
  overflow: auto;
}

.forecastHour {
  display: grid;
  /* grid-template-columns: 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem; */
  grid-template-columns: 7.5rem 4rem repeat(30, 5rem);
  /* grid-gap: 5px; */
  grid-auto-flow: row;
  font-size: 12px;
}

.forecastHour {
  grid-template-columns: 7.5rem 4rem repeat(30, 5rem);
}

.forecastHour.forecastMoment {
  grid-template-columns: 5.0rem 4.8rem repeat(60, 4.5rem);
}

.forecastHour.forecastMoment>.forecastItem {
  grid-template-rows: 0.85rem 0.85rem 0.85rem 3rem;
}

.forecastItem {
  display: grid;
  grid-template-rows: 1rem 1rem 4rem;
  justify-items: center;
  border-right: #aaa solid 1px;
  border-bottom: #aaa solid 1px;
  align-items: center;
}

.forecastItem .icon {
  width: 32px;
  border-radius: 25% 10%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
}

.we-s {
  background-color: #66dda233;
}

.we-d {
  background-color: #66dda273;
}

.forecastItem:first-child {
  border-right: none;
}

.day {
  display: inline-block;
  /* padding: 0.35em 0.65em; */
  font-size: .9em;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  margin-top: 4px;
}

.tmax {
  color: #CD486B;
}

.tmin {
  color: #70AADB;
}

.tfelt {
  color: #9b029b;
}

.precip {
  color: #095594;
  /* color: #0d610d; */
}

.wind,
.wind-text {
  vertical-align: middle;
}

.wind-green {
  color: green;
}

.wind-orange {
  color: #f37506;
}

.wind-red {
  color: red;
}

.wind-text {
  padding-left: 2px;
  font-size: 12px;
  font-weight: 600;
}

.wi-moon-new {
  color: white;
}

.wind-text-unit {
  font-size: 8px;
}

.tmax,
.tmin,
.tfelt,
.source,
.precip,
.wind {
  display: block;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
}

.wi-wind,
.wi-moon-new {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  font-size: 24px;
}

.row-head {
  font-size: 12px;
}

.forecastItem .badge {
  padding: .20em .65em;
}

.tmax::after,
.tmin::after,
.tfelt::after {
  content: '°'
}

.empty::after {
  content: '_';
  color: transparent;
}

.forecastHours img {
  width: 72px;
}

.forecastMoment img {
  width: 72px;
}


@media (max-width: 1199.98px) {
  .container {
    max-width: unset;
  }

  .forecastHour {
    grid-template-columns: 4.5rem 4rem repeat(30, 4rem);
  }

  .forecastHour.forecastMoment {
    grid-template-columns: 4.5rem 4rem repeat(60, 4.1rem);
  }

  .forecastHours img {
    width: 62px;
  }

  .forecastItem .icon {
    width: 26px;
  }
}

/* Forecast Nav */
.nav-link {
  color: #666;
  display: block;
  margin: 0;
  width: 100px;
  text-align: center;
  /* text-transform: uppercase; */
}

.forecastNavLink {
  width: 120px;
}

.nav-link::after {
  display: block;
  content: '';
  margin-top: 5px;
  border-bottom: solid 3px #019fb6;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.nav-link:hover::after {

  transform: scaleX(1);
}

.nav-link.active::after {
  display: block;
  content: '';
  margin-top: 5px;
  border-bottom: solid 3px #019fb6;
  transform: scaleX(1);
}

/* --------------- */
.nav-link1 {
  color: #666;
  display: inline-block;
  margin: 0;
  /* text-transform: uppercase; */
}

.nav-link1::after {
  display: block;
  content: '';
  border-bottom: solid 3px #019fb6;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.nav-link1:hover::after {
  transform: scaleX(1);
}



/* Style the links inside the navigation bar */
@media (max-width: 575.98px) {
  .navbar-brand {
    font-size: 18px;
  }
}

.nav-link1 {
  float: left;
  display: flex;
  /* color: #f2f2f2; */
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  border-bottom: 3px solid transparent;
  padding: 0px 10px;
  margin-right: 10px;
}

.nav-link1.active::after {
  display: block;
  content: '';
  border-bottom: solid 3px #019fb6;
}

/* Ephemeride */
#ephemeride {
  max-width: 700px;
  padding-top: 20px;
}

#ephemeride h5 {
  color: #036ba1;
  margin-bottom: 30px;
}

@media screen and (min-width: 40em) {

  #ephemeride #ephemeride_container .ephemeride_block,
  #ephemeride #ephemeride_container .ephemeride_block:last-child:not(:first-child) {
    float: left;
    clear: none;
  }

  #ephemeride #ephemeride_container .ephemeride_block:last-child:not(:first-child) {
    float: right;
  }
}

@media screen and (max-width: 39.99875em) {
  #ephemeride #ephemeride_container .ephemeride_block:not(:last-child) {
    /* margin-bottom: 30px; */
  }
}

#ephemeride #ephemeride_container .ephemeride_block ul {
  display: flex;
  justify-content: center;
  align-items: top;
  margin-bottom: 0px;
}

#ephemeride #ephemeride_container .ephemeride_block ul>li.figure {
  width: 80px;
  margin-top: -12px;
}

#ephemeride #ephemeride_container .ephemeride_block ul>li.figure figure {
  height: 52px;
  overflow: hidden;
  position: relative;
}

#ephemeride #ephemeride_container .ephemeride_block ul>li.figure figure:before {
  content: "";
  position: absolute;
  height: 80px;
  width: 100%;
  z-index: 10;
  margin-top: 12px;
}

#ephemeride #ephemeride_container .ephemeride_block ul>li.figure figure.sun_up:before {
  background: radial-gradient(ellipse at center, #7db9e8 0%, rgba(125, 185, 232, 0) 70%);
}

#ephemeride #ephemeride_container .ephemeride_block ul>li.figure figure.sun_down img {
  margin-top: 15px;
}

#ephemeride #ephemeride_container .ephemeride_block ul>li.figure figure.sun_down:before {
  background: radial-gradient(ellipse at center, #ff9442 0%, rgba(255, 148, 66, 0) 70%);
}

#ephemeride #ephemeride_container .ephemeride_block ul>li.figure figure.moon_up:before {
  background: radial-gradient(ellipse at center, #7db9e8 0%, rgba(125, 185, 232, 0) 70%);
}

#ephemeride #ephemeride_container .ephemeride_block ul>li.figure figure.moon_down img {
  margin-top: 15px;
}

#ephemeride #ephemeride_container .ephemeride_block ul>li.figure figure.moon_down:before {
  background: radial-gradient(ellipse at center, #3f4c6b 0%, rgba(63, 76, 107, 0) 70%);
}

#ephemeride #ephemeride_container .ephemeride_block ul>li.figure figure img {
  width: 60px;
  height: auto;
  display: block;
  margin: 0px auto;
  z-index: 20;
  position: relative;
}

#ephemeride #ephemeride_container .ephemeride_block ul>li.figure i {
  display: block;
  padding-top: 10px;
  border-top: 1px solid #dee2e6;
}

#ephemeride .fa-sort-up,
#ephemeride .fa-sort-down {
  text-align: center;
  margin: auto;
  display: block;
  font-size: 28px;
  height: 18px !important;
  line-height: 10px !important;
}

#ephemeride .fa-sort-down {
  margin-top: -10px;
}

#ephemeride #ephemeride_container .ephemeride_block ul .fa-sort-up {
  content: "\EA11";
  font-family: "fa";
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #1c9bd4;
}

#ephemeride #ephemeride_container .ephemeride_block ul .fa-sort-down {
  content: "\EA10";
  font-family: "fa";
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #f26522;
}

#ephemeride #ephemeride_container .ephemeride_block ul>li.text {
  width: 60px;
  padding-left: 5px;
  padding-top: 5px;
}

#ephemeride #ephemeride_container .ephemeride_block ul>li.text p {
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
}

#ephemeride #ephemeride_container .ephemeride_block ul>li.text p small {
  display: block;
  line-height: 15px;
}

#ephemeride #ephemeride_container .ephemeride_block ul>li.text p strong {
  display: block;
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}

#ephemeride #ephemeride_container .ephemeride_block h3 {
  font-size: 14px;
  color: #145e9a;
  font-weight: 500;
  font-family: "Quicksand", sans-serif;
  /* margin-top: 20px; */
  /* margin-bottom: 10px; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* #ephemeride #ephemeride_container .ephemeride_block h3:before {
  content: "\EA05";
  font-family: "fa";
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin-right: 10px;
  color: #db0084;
  font-size: 18px;
} */

/* #ephemeride #ephemeride_container .ephemeride_block h3.lune_phase:before {
  content: "\EA30";
  font-family: "fa";
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

#ephemeride #ephemeride_container .ephemeride_block h3.saint°name:before {
  content: "\EA05";
  font-family: "fa";
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
} */

/* Page maintenant */



@media (max-width: 1199.98px) {
  .hour-rain-container {
    width: 50%;
  }
}

@media (max-width: 1000px) {
  .hour-rain-container {
    width: 60%;
  }
}

@media (max-width: 800px) {
  .hour-rain-container {
    width: 90%;
  }
}

@media (max-width: 500px) {
  .hour-rain-container {
    width: 100%;
  }
}

@media (min-width: 1199.98px) {
  .hour-rain-container {
    width: 40%;
  }
}

.containerMap {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 600px;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.radarMap {
  /* width: "100%";
  height: "600px"; */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 600px;
}



.hour-rain-details .rain-data>li.rain-3 {
  background: #006ab3;
}

.hour-rain-details .rain-data>li.rain-2 {
  background: #009ee0;
}

.hour-rain-details .rain-data>li.rain-1 {
  background: #5ec5ed;
}

.hour-rain-details .rain-data>li:not(:last-child) {
  border-right: solid 1px #fff;
}

.hour-rain-details .rain-data>li:not(:last-child) {
  border-right: solid 1px #fff;
}

.hour-rain-details .rain-data>li {
  position: relative;
  display: block;
  flex: 1 1 0;
  background: #fff;
  height: 50px;
  border-radius: 2px;
  background-color: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hour-rain-details .rain-data {
  display: flex;
  margin-top: 12px;
  padding-bottom: 50px;
}

.hour-rain-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}

.hour-rain-container>h6 {
  color: #145e9a;
  padding-bottom: 5px;
}

.hour-rain-details .rain-data-legend {
  position: absolute;
  bottom: -25px;
  text-align: center;
  right: -10px;
  line-height: 14px;
  font-size: 11px;
  font-weight: 600;
  color: #9b9b9b;
}

@media (max-width: 500px) {
  .hour-rain-details .rain-data-legend {
    font-size: 9px;
  }

  .hour-rain-details .rain-data {
    padding-left: 10px;
  }
}

.hour-rain-details .rain-data>li img {
  width: 100%;
  display: block;
  height: auto;
}

.hour-rain-details .time {
  display: flex;
  justify-content: space-between;
  margin-bottom: -10px;
}

.hour-rain-details .time p {
  font-size: 12px;
  font-weight: bold;
  color: #145e9a;
  margin-bottom: auto;
}